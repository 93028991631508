import React from 'react';
import Form3 from '../Components/Form3/Form3';

const Form3Page = () => {
    return (
        <>
            <Form3 />
        </>
    )
}

export default Form3Page;