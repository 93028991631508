import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from '../Pages/HomePage';
import Form10Page from '../Pages/Form10Page';
import Form3Page from '../Pages/Form3Page';

const Router = () => {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={<HomePage />} />
                    <Route path='form10' element={<Form10Page />} />
                    <Route path='form3' element={<Form3Page />} />
                </Routes>
            </BrowserRouter>
        </>
    )
}

export default Router;