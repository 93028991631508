import React from 'react';
import Router from '../Router/Router';

const Layout = () => {
    return (
        <>
            <Router />
        </>
    )
}

export default Layout;