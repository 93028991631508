import React from 'react'
import ProductionTopBar from '../Components/ProductionTopBar/ProductionTopBar'
import ProductionSideBar from '../Components/ProductionSideBar/ProductionSideBar'

const ProductionSideBarPage = () => {
  return (
    <div>
      <ProductionTopBar />
      <ProductionSideBar />
    </div>
  )
}

export default ProductionSideBarPage
