import React from "react";
import "./Home.css";
import { Link } from "react-router-dom";
import HomeImg1 from "../../Assets/homebgs/home_card1.jpg";
import HomeImg2 from "../../Assets/homebgs/home_card2.jpg";
import HomeImg3 from "../../Assets/homebgs/home_card3.jpg";
import HomeImg4 from "../../Assets/homebgs/home_card4.jpg";
import logo from "../../Assets/logo.png";

const HomeCard = () => {

    const serviceCards = [
        { id: 1, pic: HomeImg1, title: "Office Management", path: "/" },
        { id: 2, pic: HomeImg2, title: "Production Management", path: "/form10" },
        { id: 3, pic: HomeImg3, title: "Vehicles Management", path: "/" },
        { id: 4, pic: HomeImg4, title: "Property Management", path: "/" }
    ];

    return (
        <>
            <div className="container-xxl py-2">
                <img
                    src={logo}
                    alt="logo"
                    style={{ width: "250px", margin: "20px" }}
                    className="d-md-none"
                />
                <div className=" d-flex flex-wrap justify-content-evenly">
                    {
                        serviceCards.map((service) => (
                            <div className="col-12 col-md-5 my-3" key={service.id}>
                                <Link to={service.path} style={{ textDecoration: "none" }}>
                                    <img src={service.pic} className="card-img-top" alt={service.title} />
                                    <div className="card-body">
                                        <h5 className="card-title text-center">
                                            {service.title}{" "}
                                        </h5>
                                    </div>
                                </Link>
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    );
};

export default HomeCard;
