import { Link } from "react-router-dom";
import React from "react";
import { useLocation } from "react-router-dom";
import "./ProductionTopBar.css";
import { MdOutlineAccountCircle } from "react-icons/md";
import { FaBars } from "react-icons/fa"; // Import hamburger menu icon
import ProductionSideBar from "../ProductionSideBar/ProductionSideBar"; // Import the sidebar component
import Logo from "../../Assets/logo.png"
const ProductionTopBar = () => {
  const { pathname } = useLocation();

  return (
    <div>
      <header className="header-nav nav-homepage-style light-header">
        <nav className="posr">
          <div className="container-fluid pr30 pr15-xs pl30">
            <div className="row align-items-center justify-content-between">
              <div className="col-6 col-lg-auto">
                <div className="text-center text-lg-start d-flex align-items-center">
                  {/* Toggle Button for Offcanvas Sidebar - Visible only on mobile */}
                  <div className="me-3 d-block d-lg-none">
                    <button
                      className="btn btn-link p-0 text-dark"
                      type="button"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#SidebarPanel"
                      aria-controls="SidebarPanel"
                    >
                      <FaBars size={24} />
                    </button>
                  </div>
                  {/* End Toggle Button */}

                  <div className="dashboard_header_logo position-relative me-2 me-xl-5">
                  <Link className="logo" to="/">
                      <img
                        width={200}
                        height={50}
                        src={Logo}
                        alt="Header Logo"
                      />
                    </Link>
                  </div>
                </div>
              </div>

              <div className="d-none d-lg-block col-lg-auto">
                {/* Additional content */}
              </div>

              <div className="col-6 col-lg-auto">
                <div className="text-center text-lg-end header_right_widgets">
                  <div>
                    <a href="#">
                      <MdOutlineAccountCircle size={24} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>

      {/* Offcanvas Sidebar */}
      <div
        className="offcanvas offcanvas-start"
        tabIndex="-1"
        id="SidebarPanel"
        aria-labelledby="SidebarPanelLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="SidebarPanelLabel">
            Menu
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body p-0">
          <ProductionSideBar isMobileView={true} />
        </div>
      </div>
    </div>
  );
};

export default ProductionTopBar;
