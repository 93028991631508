import React from "react";
import "./Home.css";
import HomeCard from "./HomeCard.jsx";
import logo from "../../Assets/logo.png";

const Home = () => {
    return (
        <>
            <div className="home_main_container">
                <div className="home_container d-none d-md-block col-12 col-md-6">
                    <div className='home_bg'>
                        <img
                            src={logo}
                            alt="logo"
                            style={{ width: "250px", margin: "20px" }}
                        />
                    </div>
                </div>
                <div className="col-12 col-md-6 home_right_card">
                    <div className="home_right_card_bg">
                        <HomeCard />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Home;
