import React, { useEffect } from "react";
import "./PrintableView.css";

const PrintableView = ({ formData }) => {
  useEffect(() => {
    // Cleanup function to revoke object URLs
    return () => {
      Object.keys(formData).forEach((key) => {
        const file = formData[key];
        if (file && file instanceof Blob) {
          URL.revokeObjectURL(file);
        }
      });
    };
  }, [formData]);

  const formatDate = (dateStr) => {
    if (!dateStr) return "N/A"; // Handle empty date or invalid date
    const date = new Date(dateStr);
    return date.toLocaleDateString("en-US", {
      month: "numeric", // Full month name
      day: "numeric", // Day of the month
      year: "numeric", // Full year
    });
  };

  const handlePrint = () => {
    window.print();
  };

  const handleClose = () => {
    window.location.href = "/form10";
  };

  const renderFile = (file) => {
    if (file instanceof Blob) {
      return (
        <img
          src={URL.createObjectURL(file)}
          alt="Uploaded"
          style={{
            maxWidth: "100px",
            maxHeight: "100px",
            // border: "1px solid red",
          }}
        />
      );
    } else if (typeof file === "string" && file.startsWith("http")) {
      return (
        <img
          src={file}
          alt="Uploaded"
          style={{
            maxWidth: "100px",
            maxHeight: "100px",
            border: "1px solid red",
          }}
        />
      );
    }
    return <p>No file uploaded</p>;
  };

  return (
    <div className="printable-view">
      <button className="close-button1" onClick={handleClose}>
        ×
      </button>
      <div className="boxs form_heads">
        <h1 className="h1-1">Form 10</h1>
        <h1 className="h1-1">[See Rule 19(1)]</h1>
        <h1>MANIFEST FOR HAZARDOUS AND OTHER WASTE</h1>
      </div>
      <hr />
      <table className="form_10">
        <tbody>
          <tr className="table-row">
            <td className="serial-number">1.</td>
            <td className="tab-heading">
              Sender's Name & Mailing Address (Including phone No.)
            </td>
            <td>
              : {formData.sender_name} {formData.ship_name}{" "}
              {formData.sender_address}
            </td>
          </tr>
          <tr>
            <td className="serial-number">2.</td>
            <td className="tab-heading">Sender’s Authorization No</td>
            <td>: {formData.sender_auth_no}</td>
          </tr>
          <tr>
            <td className="serial-number">3.</td>
            <td className="tab-heading">Manifest Document No</td>
            <td>: {formData.manifest_doc_no}</td>
          </tr>
          <tr>
            <td className="serial-number">4.</td>
            <td className="tab-heading">
              Transporter’s Name & Address (Including Phone No. & Email)
            </td>
            <td>: {formData.transporter_name}</td>
          </tr>
          <tr>
            <td className="serial-number">5.</td>
            <td className="tab-heading">Type of Vehicle</td>
            <td>: {formData.vehicle}</td>
          </tr>
          <tr>
            <td className="serial-number">6.</td>
            <td className="tab-heading">Transporter’s Registration No</td>
            <td>: {formData.transporter_reg_no}</td>
          </tr>
          <tr>
            <td className="serial-number">7.</td>
            <td className="tab-heading">Vehicle Registration No</td>
            <td>: {formData.vehicle_reg_no}</td>
          </tr>
          <tr>
            <td className="serial-number">8.</td>
            <td className="tab-heading">
              Receiver’s Name & Mailing Address (Including Phone & Email)
            </td>
            <td>: {formData.receiver_name}</td>
          </tr>
          <tr>
            <td className="serial-number">9.</td>
            <td className="tab-heading">Receiver’s Authorization No</td>
            <td>: {formData.receiver_auth_no}</td>
          </tr>
          <tr>
            <td className="serial-number">10.</td>
            <td className="tab-heading">Waste Description</td>
            <td>
              : {formData.waste_description?.[0]?.label || "N/A"}-
              {formData.waste_des2}
            </td>
          </tr>
          <tr>
            <td className="serial-number">11.</td>
            <td className="tab-heading">Total Quantity</td>:{" "}
            {formData.eway_qty !== undefined
              ? parseFloat(formData.eway_qty).toFixed(1)
              : "N/A"}
          </tr>
          <tr>
            <td className="serial-number">12.</td>
            <td className="tab-heading">No. Of Containers</td>
            <td>: {formData.total_qty}</td>
          </tr>
          <tr>
            <td className="serial-number">13.</td>
            <td className="tab-heading">Physical Form</td>
            <td>: {formData.physical_form}</td>
          </tr>
          <tr>
            <td className="serial-number">14.</td>
            <td className="tab-heading">
              Special Handling Instructions & Additional Information
            </td>
            <td className="print_view_text">
              : Use of Personal Protective Equipments, I hereby declare that the
              contents of the consignment are fully and accurately described
              above by proper shipping name and are categorized, packed, marked,
              and labeled, and are in all respects in proper conditions for
              transport by road according to applicable national government
              regulations
            </td>
          </tr>
          <tr className="table-row">
            <td className="serial-number">15.</td>
            <td className="tab-heading">Sender’s Certificate</td>
            <td className="print_view_text">
              : I hereby declare that the contents of the consignment are fully
              and accurately described above by proper shipping name and are
              categorized, packed, marked, and labeled, and are in all respects
              in proper conditions for transport by road according to applicable
              national government regulations
            </td>
          </tr>

          <tr className="upload row-18 ">
            <td colSpan="4">
              <div className="row-content cert-all">
                <div className="image-label-container ">
                  {/* {renderFile(
                    formData.receiver_certificate_name_stamp
                  )} */}
                  <label>Name & Stamp</label>
                </div>
                <div className="image-label-container">
                  {/* {renderFile(
                    formData.sender_cert_sign
                  )} */}
                  <label>(Signature)</label>
                </div>
                <div className="date-label-container">
                  <label>Month Date Year</label>
                  <span>{formatDate(formData.sender_cert_date)}</span>
                </div>
              </div>
            </td>
          </tr>

          <tr className="upload row-18 receipt">
            <td className="serial-number tab-heading1">16.</td>
            <td className="tab-heading1" colSpan="2">
              Transporter’s Acknowledgement of receipt of Wastes:
            </td>
          </tr>
          <tr className="upload row-18">
            <td colSpan="4">
              <div className="row-content">
                <div className="image-label-container">
                  <label>Name & Stamp:</label>
                  {renderFile(
                    formData.transporter_ack_receipt_wastes_name_stamp
                  )}
                </div>
                <div className="image-label-container">
                  <label>(Signature):</label>
                  {renderFile(
                    formData.transporter_ack_receipt_wastes_signature
                  )}
                </div>
                <div className="date-label-container">
                  <label>Month Date Year</label>
                  <span>
                    {formatDate(formData.transporter_ack_receipt_wastes_date)}
                  </span>
                </div>
              </div>
            </td>
          </tr>
          <tr className="upload row-19">
            <td className="serial-number">17.</td>
            <td className="tab-heading" colSpan="2">
              Receiver’s Certification for receipt of hazardous and other
              wastes:
            </td>
          </tr>
          <tr className="upload row-19">
            <td colSpan="4">
              <div className="row-content">
                <div className="image-label-container">
                  <label>Name & Stamp:</label>
                  {renderFile(formData.receiver_certificate_name_stamp)}
                </div>
                <div className="image-label-container">
                  <label>(Signature):</label>
                  {renderFile(formData.receiver_certificate_signature)}
                </div>
                <div className="date-label-container">
                  <label>Month Date Year</label>
                  <span>{formatDate(formData.receiver_certificate_date)}</span>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <div className="footer-note">
        <p>
          <b>NOTE :</b> Please attach comprehensive Analysis Report Photocopy
        </p>
        <p>
          <b>First Copy :</b> To be forwarded to the TNPCB by the sender
        </p>
      </div>
      <div
        className="control-group"
        style={{ textAlign: "center", padding: "8px 13px" }}
      >
        <button
          style={{ textAlign: "center", padding: "8px 13px" }}
          onClick={handlePrint}
        >
          Print
        </button>
      </div>
    </div>
  );
};

export default PrintableView;
