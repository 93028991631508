import React from 'react';
import Form10 from '../Components/Form10/Form10';

const Form10Page = () => {
    return (
        <>
            <Form10 />
        </>
    )
}

export default Form10Page;